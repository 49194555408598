import { Injectable } from '@angular/core';
import { Entity } from '../models/entity.model';
import { Card, CardType } from '@lorient-emploi-front/shared/ui/components';
import { Occupation } from '../models/occupation.model';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  getUrl(type: string): string {
    switch (type) {
      case 'company':
        return 'employeurs';
    
      case 'event':
        return 'evenements';
    
      case 'job':
        return 'metiers';
    
      case 'location':
        return 'lieux';
    
      case 'news':
        return 'actualites';
    
      case 'organization':
        return 'organismes';
    
      case 'sector':
        return 'secteurs-activites';
    
      default:
        return 'recherche'
    }
  }

  getTypeName(type: string): string {
    switch (type) {
      case 'company':
        return 'Employeurs';
    
      case 'event':
        return 'Évènements';
    
      case 'job':
        return 'Métiers';
    
      case 'location':
        return 'Lieux';
    
      case 'news':
        return 'Actualités';
    
      case 'organization':
        return 'Organismes';
    
      case 'sector':
        return 'Secteurs d\'activités';
    
      default:
        return 'Employeurs'
    }
  }

  getSingleTypeName(type: string): string {
    switch (type) {
      case 'company':
      case 'companies':
        return 'Employeur';
    
      case 'event':
      case 'events':
        return 'Évènement';
    
      case 'job':
      case 'jobs':
        return 'Métier';
    
      case 'location':
      case 'locations':
        return 'Lieu';
    
      case 'news':
        return 'Actualité';
    
      case 'organization':
      case 'organizations':
        return 'Organisme';
    
      case 'sector':
      case 'sectors':
        return 'Secteur d\'activité';
    
      default:
        return 'Employeur'
    }
  }

  getTypeColor(type: string): string {
    switch (type) {
      case 'companies':
        return 'purple';
    
      case 'events':
        return 'green';
    
      case 'jobs':
        return 'blue';
    
      case 'news':
        return 'green';
    
      case 'organizations':
        return 'yellow';
    
      case 'sectors':
      default:
        return 'orange';
    }
  }

  buildCards(entities: Entity[]): Card[] {
    return entities.map((entity) => ({
      type: entity.type as CardType,
      id: entity.id.toString(),
      src: entity.image,
      title: entity.name,
      link: `/${this.getUrl(entity.type)}/${entity.slug}`,
      date: entity.date,
      city: entity.city,
      tag: entity.tag,
      description: entity.description,
      location: entity.city,
      segment: entity.segments.length > 0 ? entity.segments[0] : entity.tag,
      category: entity.categories.length > 0 ? entity.categories[0] : entity.tag,
    }));
  }

  buildCardsFromOccupations(occupations: Occupation[], imageSrc: string): Card[] {
    return occupations.map((occupation) => ({
      type: 'job_sector' as CardType,
      id: occupation.id.toString(),
      src: imageSrc,
      title: occupation.name,
      link: `/metier-entreprise/${occupation.slug}`,
    }));
  }
}
