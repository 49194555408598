import { HttpParams } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { ApiService } from '@lorient-emploi-front/core/http-client';
import { signalState, patchState } from '@ngrx/signals';
import { MapEntity, MapSectorEntity } from '../models/entity.model';

type SearchState = { 
  searchResult: MapEntity[];
  isLoading: boolean;
  searchTerm: string;
  filterRange: number;
  filterLocationLat: number;
  filterLocationLong: number;
  filterModels: string[];
  filterSectors: number[];
  sectorsList: MapSectorEntity[];
};

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private readonly _apiService = inject(ApiService);

  readonly _searchState = signalState<SearchState>({
    searchResult: [],
    isLoading: true,
    searchTerm: '',
    filterModels: ['company'],
    filterSectors: [],
    filterRange: 50,
    filterLocationLat: 47.75,
    filterLocationLong: -3.36667,
    sectorsList: []
  });

  readonly searchTerm = this._searchState.searchTerm;
  readonly filterSectors = this._searchState.filterSectors;
  readonly filterModels = this._searchState.filterModels;
  readonly filterRange = this._searchState.filterRange;
  readonly filterLocationLat = this._searchState.filterLocationLat;
  readonly filterLocationLong = this._searchState.filterLocationLong;

  getMapSectors() {
    this._apiService.get<MapSectorEntity[]>('/map/sectors').subscribe({
      next: response => patchState(this._searchState, { sectorsList: response }),
    });
  }

  updateSearchState(state: SearchState) {
    patchState(this._searchState, state);
  }

  resetSearchParams() {
    patchState(this._searchState, {
      searchResult: [],
      searchTerm: '',
      filterModels: [],
      filterSectors: [],
      filterRange: 50,
      filterLocationLat: 47.75,
      filterLocationLong: -3.36667
    });
  }

  getSearch() {      
    patchState(this._searchState, {
      isLoading: true
    });
    let params = new HttpParams()
    .set('term', this.searchTerm())
    .set('lat', this.filterLocationLat().toString())
    .set('long', this.filterLocationLong().toString())
    .set('range', this.filterRange().toString());

    this.filterSectors().forEach(sector => {
      params = params.append('sectors[]', sector);
    });

    this.filterModels().forEach(model => {
      params = params.append('models[]', model);
    });

    this._apiService.get<MapEntity[]>('/map', params).subscribe({
      next: response => patchState(this._searchState, {
        isLoading: false,
        searchResult: response.map(entity => {
          let link = `/employeurs/${entity.slug}`;

          if (entity.type === 'organization') {
            link = `/organismes/${entity.slug}`;
          }

          if (entity.type === 'event') {
            link = `/evenements/${entity.slug}`;
          }

          return ({
            ...entity,
            link 
          })
        })
      }),
      error: err => patchState(this._searchState, { isLoading: false })
    });
  }
}
